import { setShowOverlay, setMessages } from "../store/actions";
import store from "../store/store";
import * as wss from "./wss";
import Peer from "simple-peer";
import { fetchTURNCredentials, getTurnIceServers } from "./turn";

const defaultConstraints = {
  audio: true,
  video: {
    width: "480",
    height: "360",
  },
};

const onlyAudioConstraints = {
  audio: true,
  video: false,
};

let localStream;

export const getLocalPreviewAndInitRoomConnection = async (
  isRoomHost,
  identity,
  title,
  description,
  roomId = null,
  onlyAudio
) => {
  await fetchTURNCredentials();

  const constraints = onlyAudio ? onlyAudioConstraints : defaultConstraints;

  navigator.mediaDevices
    .getUserMedia(constraints)
    .then((stream) => {
      console.log("successfuly received local stream");
      localStream = stream;
      showLocalVideoPreview(localStream);

      // dispatch an action to hide overlay
      store.dispatch(setShowOverlay(false));

      isRoomHost
        ? wss.createNewRoom(identity, title, description, onlyAudio)
        : wss.joinRoom(identity, roomId, onlyAudio);
    })
    .catch((err) => {
      console.log(
        "error occurred when trying to get an access to local stream"
      );
      console.log(err);
    });
};

let peers = {};
let streams = [];

const getConfiguration = () => {
  const turnIceServers = getTurnIceServers();

  if (turnIceServers) {
    return {
      iceServers: [
        {
          urls: "stun:stun.l.google.com:19302",
        },
        ...turnIceServers,
      ],
    };
  } else {
    console.warn("Using only STUN server");
    return {
      iceServers: [
        {
          urls: "stun:stun.l.google.com:19302",
        },
      ],
    };
  }
};

const messengerChannel = "messenger";

export const prepareNewPeerConnection = (connUserSocketId, isInitiator) => {
  const configuration = getConfiguration();

  peers[connUserSocketId] = new Peer({
    initiator: isInitiator,
    config: configuration,
    stream: localStream,
    channelName: messengerChannel,
  });

  peers[connUserSocketId].on("signal", (data) => {
    // webRTC offer, webRTC Answer (SDP informations), ice candidates

    const signalData = {
      signal: data,
      connUserSocketId: connUserSocketId,
    };

    wss.signalPeerData(signalData);
  });

  peers[connUserSocketId].on("stream", (stream) => {
    console.log("new stream came");

    addStream(stream, connUserSocketId);
    streams = [...streams, stream];
  });

  peers[connUserSocketId].on("data", (data) => {
    const messageData = JSON.parse(data);
    appendNewMessage(messageData);
  });
};

export const handleSignalingData = (data) => {
  //add signaling data to peer connection
  peers[data.connUserSocketId].signal(data.signal);
};

export const removePeerConnection = (data) => {
  const { socketId } = data;
  const videoContainer = document.getElementById(socketId);
  const videoEl = document.getElementById(`${socketId}-video`);

  if (videoContainer && videoEl) {
    const tracks = videoEl.srcObject.getTracks();

    tracks.forEach((t) => t.stop());

    videoEl.srcObject = null;
    videoContainer.removeChild(videoEl);

    videoContainer.parentNode.removeChild(videoContainer);

    if (peers[socketId]) {
      peers[socketId].destroy();
    }
    delete peers[socketId];
  }
};

////////////////////////////////// UI Videos //////////////////////////////////
const showLocalVideoPreview = (stream) => {
  const videosContainer = document.getElementById("videos_portal");
  videosContainer.classList.add("videos_portal_styles");
  const videoContainer = document.createElement("div");
  videoContainer.classList.add("video_track_container");
  const videoElement = document.createElement("video");
  videoElement.autoplay = true;
  videoElement.muted = true;
  videoElement.srcObject = stream;

  videoElement.onloadedmetadata = () => {
    videoElement.play();
  };

  videoContainer.appendChild(videoElement);

  // Add border detection for sound (speech)
  const audioContext = new AudioContext();
  const analyser = audioContext.createAnalyser();
  const microphone = audioContext.createMediaStreamSource(stream);
  microphone.connect(analyser);

  analyser.fftSize = 256;
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  const borderElement = document.createElement("div");
  borderElement.classList.add("speech-border");
  videoContainer.appendChild(borderElement);

  const updateBorder = () => {
    analyser.getByteFrequencyData(dataArray);
    const average = dataArray.reduce((a, b) => a + b, 0) / bufferLength;

    // Threshold for speech detection (adjust as needed)
    const threshold = 15;

    if (average > threshold) {
      borderElement.style.backgroundImage = "url('https://voice.intelligichain.com/static/img/mic.gif')";
      borderElement.style.backgroundSize = "cover";
      borderElement.style.height = "50px";
      borderElement.style.width = "50px";
      borderElement.style.position = "absolute";
      // borderElement.style.border = "3px solid red";
      borderElement.style.backgroundColor = "unset";
      borderElement.style.borderRadius = "100%";
      borderElement.style.backgroundPosition = "center";
      borderElement.style.display = "block";
      borderElement.style.bottom = "2%";
      borderElement.style.right = "2%";
      borderElement.style.zIndex = "999";
    } else {
      borderElement.style.display = "none";
    }
  };

  // Update border periodically
  setInterval(updateBorder, 100);

  if (store.getState().connectOnlyWithAudio) {
    videoContainer.appendChild(getAudioOnlyLabel());
  }

  videosContainer.appendChild(videoContainer);
};

const addStream = (stream, connUserSocketId) => {
  //display incoming stream
  const videosContainer = document.getElementById("videos_portal");
  const videoContainer = document.createElement("div");
  videoContainer.id = connUserSocketId;

  videoContainer.classList.add("video_track_container");
  const videoElement = document.createElement("video");
  videoElement.autoplay = true;
  videoElement.srcObject = stream;
  videoElement.id = `${connUserSocketId}-video`;

  videoElement.onloadedmetadata = () => {
    videoElement.play();
  };

  videoElement.addEventListener("click", () => {
    if (videoElement.classList.contains("full_screen")) {
      videoElement.classList.remove("full_screen");
    } else {
      videoElement.classList.add("full_screen");
    }
  });

  // speech border for remote 

// Add border detection for sound (speech)
const audioContext = new AudioContext();
const analyser = audioContext.createAnalyser();
const microphone = audioContext.createMediaStreamSource(stream);
microphone.connect(analyser);

analyser.fftSize = 256;
const bufferLength = analyser.frequencyBinCount;
const dataArray = new Uint8Array(bufferLength);

const borderElement = document.createElement("div");
borderElement.classList.add("speech-border");
videoContainer.appendChild(borderElement);

const updateBorder = () => {
  analyser.getByteFrequencyData(dataArray);
  const average = dataArray.reduce((a, b) => a + b, 0) / bufferLength;

  // Threshold for speech detection (adjust as needed)
  const threshold = 15;

  if (average > threshold) {
    // borderElement.style.border = "5px solid #6681FA";
    // borderElement.style.height = "100%";
    // borderElement.style.width = "88%";
    // borderElement.style.position = "absolute";
    // // borderElement.style.background = "red";
    // borderElement.style.borderRadius = "20px";
    // borderElement.style.display = "block";

    borderElement.style.backgroundImage = "url('https://voice.intelligichain.com/static/img/mic.gif')";
    borderElement.style.backgroundSize = "cover";
    borderElement.style.height = "50px";
    borderElement.style.width = "50px";
    borderElement.style.position = "absolute";
    // borderElement.style.border = "3px solid red";
    borderElement.style.backgroundColor = "unset";
    borderElement.style.borderRadius = "100%";
    borderElement.style.backgroundPosition = "center";
    borderElement.style.display = "block";
    borderElement.style.bottom = "2%";
    borderElement.style.right = "5%";
    borderElement.style.zIndex = "999";
  } else {
    borderElement.style.display = "none";
  }
};

// Update border periodically
setInterval(updateBorder, 100);

  // ------------- 

  videoContainer.appendChild(videoElement);

  // check if other user connected only with audio
  const participants = store.getState().participants;

  const participant = participants.find((p) => p.socketId === connUserSocketId);
  if (participant?.onlyAudio) {
    videoContainer.appendChild(getAudioOnlyLabel(participant.identity));
  } else {
    // videoContainer.style.position = "static";
    videoContainer.style.position = "relative";
  }

  videosContainer.appendChild(videoContainer);
};

// const getAudioOnlyLabel = (identity = "") => {
//   const labelContainer = document.createElement("div");
//   labelContainer.classList.add("label_only_audio_container");

//   const label = document.createElement("p");
//   label.classList.add("label_only_audio_text");
//   label.innerHTML = `Only audio ${identity}`;

//   labelContainer.appendChild(label);
//   return labelContainer;
// };

const getAudioOnlyLabel = (identity = "") => {
  const labelContainer = document.createElement("div");
  labelContainer.classList.add("label_only_audio_container");
  const avatar = document.createElement("img");
  avatar.classList.add("user_avatar");
  if (identity == '') {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : 'default';
    avatar.src = `https://api.multiavatar.com/${user.name}.png`;
  } else {
    avatar.src = `https://api.multiavatar.com/${identity}.png`;
  }

  labelContainer.appendChild(avatar);
  return labelContainer;
};


////////////////////////////////// Buttons logic //////////////////////////////////

export const toggleMic = (isMuted) => {
  localStream.getAudioTracks()[0].enabled = isMuted ? true : false;
};

export const toggleCamera = (isDisabled) => {
  localStream.getVideoTracks()[0].enabled = isDisabled ? true : false;
};

export const toggleScreenShare = (
  isScreenSharingActive,
  screenSharingStream = null
) => {
  if (isScreenSharingActive) {
    switchVideoTracks(localStream);
  } else {
    switchVideoTracks(screenSharingStream);
  }
};

const switchVideoTracks = (stream) => {
  for (let socket_id in peers) {
    for (let index in peers[socket_id].streams[0].getTracks()) {
      for (let index2 in stream.getTracks()) {
        if (
          peers[socket_id].streams[0].getTracks()[index].kind ===
          stream.getTracks()[index2].kind
        ) {
          peers[socket_id].replaceTrack(
            peers[socket_id].streams[0].getTracks()[index],
            stream.getTracks()[index2],
            peers[socket_id].streams[0]
          );
          break;
        }
      }
    }
  }
};

////////////////////////////////// Messages /////////////////////////////////////
const appendNewMessage = (messageData) => {
  const messages = store.getState().messages;
  store.dispatch(setMessages([...messages, messageData]));
};

export const sendMessageUsingDataChannel = (messageContent) => {
  // append this message locally
  const identity = store.getState().identity;

  const localMessageData = {
    content: messageContent,
    identity,
    messageCreatedByMe: true,
  };

  appendNewMessage(localMessageData);

  const messageData = {
    content: messageContent,
    identity,
  };

  const stringifiedMessageData = JSON.stringify(messageData);
  for (let socketId in peers) {
    peers[socketId].send(stringifiedMessageData);
  }
};
